import React, { useEffect, useState } from 'react'
import TitledData from 'components/common/TitledData'
import CardContent from '@mui/material/CardContent'
import { useTheme } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import GenericText from 'components/common/GenericText'
import GenericHeading from 'components/common/GenericHeading'
import GenericCard from 'components/common/GenericCard'
import GenericCardHeader from 'components/common/GenericCardHeader'
import Typography from '@mui/material/Typography'
import { originalSourceToImageMap } from 'components/BusinessSource/specs'
import { OverviewDetails } from 'store/verticals/types'
import { formatDate, getSellerRegitryStatus } from './utils'
import styles from './ActivityOverviewCard.scss'
import { useSellerCartCheckMessage } from 'containers/VerticalPage/common/hooks'
import { SellerCartCheckStatus } from 'containers/VerticalPage/common/types'

const employeeIconUrl = require('./icons/employee.svg')
const addressIconUrl = require('./icons/address.svg')

const cartTooltipInfo =
  "These parameters reflect the overall magnitude of the seller's business activity, based on the factors below. They provide insights into the seller's operational scale and financial performance."

interface ActivityOverviewProps {
  overviewData: OverviewDetails
}

const titleSx = {
  fontSize: { md: 14, lg: 14, xl: 15, xxl: 20, xxxl: 28 },
  lineHeight: { md: '13px', lg: '14px', xl: '18px', xxl: '24px', xxxl: '26px' },
  minHeight: { md: '28px', lg: '28px', xl: 'auto', xxl: '40px', xxxl: '45px' },
  textAlign: 'start',
  color: '#707991',
}

const valuesSx = {
  fontSize: { md: '14px', lg: '16px', xl: '22px', xxl: '24px', xxxl: '36px' },
  lineHeight: { md: '14px', lg: '20px', xl: '26px', xxl: '32px', xxx: '38px' },
  color: '#1C2842',
  fontFamily: 'Dosis',
  fontWeight: 500,
  textTransform: 'capitalize',
}

const dataSx = {
  maxWidth: '150px',
  textAlign: 'start',
}

const ActivityOverview = ({ overviewData }: ActivityOverviewProps) => {
  const theme = useTheme()

  const [cartTooltipText, setCartTooltipText] = useState<string | null>(null)

  const sellerCartCheckMessage = useSellerCartCheckMessage(overviewData?.cartCheckStatus)

  useEffect(() => {
    if (sellerCartCheckMessage === SellerCartCheckStatus.MIX_TAX_COLLECTED) {
      setCartTooltipText(cartTooltipInfo)
    }
  }, [sellerCartCheckMessage])

  return (
    <GenericCard sx={{ gridColumn: '2 / 3', display: 'flex', flexDirection: 'column' }}>
      <GenericCardHeader
        sx={{ bgcolor: theme.palette.secondary.main }}
        title={
          <GenericHeading
            sx={{
              color: '#fff',
              fontSize: { md: '16px', lg: '18px', xl: '20px', xxl: '26px', xxxl: '36px' },
              p: '16px',
              fontWeight: 500,
              textAlign: 'center',
            }}
          >
            ACTIVITY OVERVIEW
          </GenericHeading>
        }
      />
      <CardContent
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexGrow: 1,
          p: { md: '8px 10px', lg: '12px 16px', xl: '15px 27px', xxl: '22px 30px', xxxl: '26px 34px' },
        }}
      >
        <Stack direction='row' sx={{ justifyContent: 'space-between', p: '0', gap: '10px', flexGrow: 1 }}>
          <TitledData
            title='Activity Type'
            data={<GenericText sx={{ ...valuesSx }}>{overviewData.activityType}</GenericText>}
            titleSx={titleSx}
            key='Activity Type'
            dataSx={dataSx}
          />
          <TitledData
            title='Active Verticals'
            data={
              <Stack direction='row' spacing={1}>
                {overviewData.activeVerticals.map(vertical => (
                  <img
                    src={originalSourceToImageMap[vertical]}
                    alt={vertical}
                    key={vertical}
                    className={styles.verticalIcon}
                  />
                ))}
              </Stack>
            }
            titleSx={titleSx}
            key='Active Verticals'
            dataSx={dataSx}
          />
          <TitledData
            title='First Activity'
            data={<GenericText sx={{ ...valuesSx }}>{formatDate(overviewData.firstActivity)}</GenericText>}
            titleSx={titleSx}
            key='First Activity'
            dataSx={dataSx}
          />
          <TitledData
            title='Seller Registry Status'
            data={
              <Typography sx={{ ...valuesSx, fontWeight: 600, textTransform: 'uppercase' }}>
                {getSellerRegitryStatus(overviewData?.risk?.registrationStatus)}
              </Typography>
            }
            titleSx={titleSx}
            key='Seller Registry Status'
            dataSx={dataSx}
          />
          <TitledData
            title='Overall Seller Cart Check Status'
            data={
              <GenericText sx={{ ...valuesSx, fontWeight: 600, textTransform: 'uppercase' }}>
                {sellerCartCheckMessage}
              </GenericText>
            }
            titleSx={titleSx}
            key='Cart Check Status'
            dataSx={dataSx}
            tooltipData={
              cartTooltipText
                ? {
                    iconSx: {},
                    text: cartTooltipText,
                  }
                : undefined
            }
          />
          <TitledData
            title='Physical nexus'
            data={
              <Stack>
                {!overviewData.risk?.physicalNexus?.business && !overviewData.risk?.physicalNexus?.employees ? (
                  <GenericText sx={{ ...valuesSx, fontWeight: 600 }}>NOT DETECTED</GenericText>
                ) : (
                  <Stack direction='row' gap={1} sx={{ ...valuesSx, fontWeight: 600 }}>
                    {overviewData.risk?.physicalNexus?.business && (
                      <img src={addressIconUrl} alt={'Physical nexus address'} className={styles.verticalIcon} />
                    )}
                    {overviewData.risk?.physicalNexus?.employees && (
                      <img src={employeeIconUrl} alt={'Physical nexus employees'} className={styles.verticalIcon} />
                    )}
                  </Stack>
                )}
              </Stack>
            }
            titleSx={titleSx}
            key='Physical nexus'
            dataSx={dataSx}
          />
        </Stack>
      </CardContent>
    </GenericCard>
  )
}

export default ActivityOverview
