import React, { useCallback } from 'react'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import Icon from 'components/Icon'
import styles from './NavigationHeader.scss'

enum HEADER_OPTIONS {
  BUSINESSES = '/businesses',
  SINGLE_BUSINESS = '/singleBusiness',
  RENTALS = '/rentals',
  NFT = '/nft',
  VERTICAL = '/vertical',
  MANAGEMENT = '/management',
  INFLUENCERS = '/influencers',
  ECOMMERCE_EXTENDED = '/ecommerce-extended',
  ECOMMERCE = '/ecommerce',
  FREELANCERS = '/freelancers',
  DATA_SCIENCE = '/data-science',
  OFFSHORE = '/offshore',
  COMPANY_OVERVIEW = '/company-overview',
  SERVICE_PROVIDER = '/service-provider',
}

interface NavigationHeaderProps {
  entityParam?: string
}

const NavigationHeader: React.FC<NavigationHeaderProps> = ({ entityParam }) => {
  const history = useHistory()

  const HOME_LINK = (
    <Link to='/'>
      <Icon name='navigationHome' size='xsmall' svgClassName={styles.svgClassName} className={styles.icon} /> Home
    </Link>
  )

  const {
    BUSINESSES,
    RENTALS,
    NFT,
    SINGLE_BUSINESS,
    VERTICAL,
    MANAGEMENT,
    INFLUENCERS,
    ECOMMERCE,
    ECOMMERCE_EXTENDED,
    FREELANCERS,
    DATA_SCIENCE,
    OFFSHORE,
    COMPANY_OVERVIEW,
    SERVICE_PROVIDER,
  } = HEADER_OPTIONS

  const headersMap: Record<HEADER_OPTIONS, JSX.Element> = {
    [BUSINESSES]: <Link to='/businesses'>Business Mapper</Link>,
    [RENTALS]: <Link to='/rentals'>Short Term Rentals</Link>,
    [SINGLE_BUSINESS]: (
      <>
        <Link to='/businesses'>Business Mapper</Link> / <span className={styles.param}>{entityParam}</span>
      </>
    ),
    [VERTICAL]: (
      <>
        <Link to={`/${entityParam}`}>Vertical</Link> /{' '}
        <span className={styles.param}>{entityParam?.replaceAll('-', ' ')}</span>
      </>
    ),
    [MANAGEMENT]: <Link to='/management'>Management</Link>,
    [INFLUENCERS]: <Link to='/influencers'>Influencers</Link>,
    [ECOMMERCE]: <Link to='/ecommerce'>Ecommerce</Link>,
    [ECOMMERCE_EXTENDED]: <Link to='/ecommerce-extended'>Ecommerce</Link>,
    [NFT]: <Link to='/nft'>NFT Mapper</Link>,
    [FREELANCERS]: <Link to='/freelancers'>Freelancers</Link>,
    [DATA_SCIENCE]: <Link to='/data-science'>Data Science</Link>,
    [OFFSHORE]: <Link to='offshore'>OFFSHORE</Link>,
    [COMPANY_OVERVIEW]: <Link to='/company-overview'>Company Overview</Link>,
    [SERVICE_PROVIDER]: <Link to='/service-providers'>Service Providers</Link>,
  }

  const renderHeader = useCallback(() => {
    const currentHeaderKey: string | undefined = Object.keys(HEADER_OPTIONS).find(key =>
      history.location.pathname.includes(HEADER_OPTIONS[key]),
    )

    if (!currentHeaderKey) return HOME_LINK
    return (
      <>
        {HOME_LINK} / {headersMap[HEADER_OPTIONS[currentHeaderKey]]}
      </>
    )
  }, [history.location])

  return <div className={styles.navigationHeader}>{renderHeader()}</div>
}

export default NavigationHeader
