import React from 'react'
import GenericCardHeader from 'components/common/GenericCardHeader'
import Stack from '@mui/material/Stack'
import { useTheme } from '@mui/material/styles'
import styles from './styles'
import VerticalCardAvatar from 'containers/VerticalPage/common/VerticalCard/VerticalCardAvatar'
import VerticalCardHeadingLink from 'containers/VerticalPage/common/VerticalCard/VerticalCardHeadingLink'
import GenericLink from 'components/common/GenericLink'
import { usePlatformIcon } from 'containers/VerticalPage/common/VerticalCard/usePlatformIcon'
import CardToggleCaret from 'containers/VerticalPage/common/VerticalCard/CardToggleCaret'
import { CaretTypes } from 'containers/VerticalPage/common/VerticalCard/types'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'

interface CollapsedVerticalCardProps {
  platformName: string
  profileImage: string
  headingLink: string
  profileName: string
  handleToggleCards: () => void
  cardHeaderBgColor?: string
  cardHeadingLinkColor?: string
  customPlatformImg?: string
}

const CollapsedVerticalCard = ({
  platformName,
  headingLink,
  profileImage,
  profileName,
  handleToggleCards,
  cardHeaderBgColor,
  cardHeadingLinkColor,
  customPlatformImg,
}: CollapsedVerticalCardProps) => {
  const theme = useTheme()
  const platformIcon = usePlatformIcon(platformName)

  return (
    <GenericCardHeader
      sx={{
        bgcolor: cardHeaderBgColor || theme.palette.platforms[platformName] || theme.palette.secondary.main,
        borderRadius: '16px',
        p: '1.75rem 1rem',
      }}
      title={
        <Stack alignItems='center' justifyContent='space-between' flexDirection='row'>
          <Stack sx={{ ...styles.HeaderContainer, ...styles.CollapsedHeaderContainer }}>
            {platformIcon && <img style={styles.PlatformIcon} src={customPlatformImg || platformIcon} alt='Platform' />}
          </Stack>
          <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
            <VerticalCardAvatar profileImage={profileImage} sx={styles.CollapsedAvatar} />
            <VerticalCardHeadingLink
              href={normalizeUrlProtocol(headingLink)}
              profileName={profileName}
              sx={{
                ...styles.CollapsedHeadingLink,
                ...styles.CommonHeadingLink,
                color: cardHeadingLinkColor ? cardHeadingLinkColor : 'white',
              }}
            />
          </Stack>
          <CardToggleCaret
            caretType={CaretTypes.Expand}
            handleClick={handleToggleCards}
            caretColor={cardHeadingLinkColor}
          />
        </Stack>
      }
    />
  )
}

export default CollapsedVerticalCard
