import React from 'react'
import Box from '@mui/material/Box'
import styles from './RegionTooltip.scss'
import { ReactSVG } from 'react-svg'
import { Business } from '../LeafletMap'
import Icon from 'components/Icon'
import { tabsIds } from 'containers/VerticalPage/ecommerce-extended/Details/utils'

interface RegionTooltipProps {
  displayName: string
  countryCode: string
  stateCode: string
  employees: number[]
  businesses: Business[]
  setActiveTab?: (tab: number) => void
  setEmployeeIds?: (employeeIds: number[]) => void
  handleClickOpen?: () => void
}

const RegionTooltip = ({
  displayName,
  countryCode,
  stateCode,
  employees,
  businesses,
  setActiveTab,
  setEmployeeIds,
  handleClickOpen,
}: RegionTooltipProps) => {
  const handleViewEmployees = () => {
    if (setEmployeeIds) {
      setEmployeeIds(employees)
    }

    if (setActiveTab) {
      setActiveTab(tabsIds.EMPLOYEES)
    }
  }

  return (
    <>
      <Box className={styles.regionTooltip}>
        <Box className={styles.tooltipHeader}>
          <div>
            <ReactSVG
              src={require(`../assets/${stateCode ? `stateFlags/${stateCode}` : `flags/${countryCode}`}.svg`)}
              className={styles.flagIcon}
            />
            <p className={styles.regionName}>{displayName}</p>
          </div>
        </Box>
        <Box className={styles.tooltipSubHeader}>
          <Box className={styles.tooltipSubHeaderItem}>
            <p>{new Set(employees).size} Active Employees Found</p>
            <button
              onClick={handleViewEmployees}
              className={styles.employeesButton}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}
              disabled={!(employees.length > 0)}
            >
              <p>View Employees</p>
              <Icon name='arrowRight' size='small' />
            </button>
          </Box>
          <Box className={styles.tooltipSubHeaderItem}>
            <p>{new Set(businesses).size} Active Business Addresses Found</p>
            <button
              onClick={handleClickOpen}
              disabled={!(businesses.length > 0)}
              className={styles.businessesButton}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '6px' }}
            >
              <p>View Details</p>
              <Icon name='arrowRight' size='small' />
            </button>
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default RegionTooltip
