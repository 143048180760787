import React, { useState, useRef } from 'react'
import { MapContainer, TileLayer, Marker, Tooltip } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import styles from './LeafletMap.scss'
import AddZoomControl from './ZoomControl/ZoomControl'
import { businessMarker, createPieChartMarkerFromCounts, employeeMarker } from './Utils'
import RegionTooltip from './RegionTooltip/RegionTooltip'
import { useSelector } from 'react-redux'
import { selectMapBaseUrl, selectPlatform } from 'store/global'
import { ISource } from 'store/verticals/types'
import DetailsDialog from './DetailsDialog/DetailsDialog'

export type Business = {
  address: string
  sources: ISource[]
}

export interface Location {
  displayName: string
  employees: number[]
  businesses: Business[]
  countryCode: string
  latitude: number
  longitude: number
  stateCode: string
}

interface LeafletMapProps {
  locations: Location[]
  setActiveTab?: (tab: number) => void
  setEmployeeIds?: (employeeIds: number[]) => void
}

const LeafletMap = ({ locations, setActiveTab, setEmployeeIds }: LeafletMapProps) => {
  const mapBaseUrl = useSelector(selectMapBaseUrl)
  const platform = useSelector(selectPlatform)
  const [activeTooltip, setActiveTooltip] = useState<number | null>(null)
  const [tooltipHovered, setTooltipHovered] = useState(false)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const [currentLocation, setCurrentLocation] = useState<Location>()
  const [open, setOpen] = useState(false)

  const tileLayersURL =
    platform === 'prem' ? `http://${mapBaseUrl}/tile/{z}/{x}/{y}.png` : `https://{s}.${mapBaseUrl}/{z}/{x}/{y}.png`

  const handleMarkerMouseOver = (index: number, location: Location) => {
    clearTimeout(timeoutRef.current!)
    setCurrentLocation(location)
    setActiveTooltip(index)
  }

  const handleTooltipMouseOver = () => {
    setTooltipHovered(true)
  }

  const handleTooltipMouseOut = () => {
    setTooltipHovered(false)
    if (timeoutRef.current) clearTimeout(timeoutRef.current)
    timeoutRef.current = setTimeout(() => {
      setActiveTooltip(null)
    }, 200)
  }

  const handleMarkerMouseOut = (index: number) => {
    if (activeTooltip === index && !tooltipHovered) {
      timeoutRef.current = setTimeout(() => {
        setActiveTooltip(null)
      }, 200)
    }
  }

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <MapContainer
      className={styles.container}
      attributionControl={false}
      center={[-54.9, -86.3]}
      zoom={1.5}
      zoomControl={false}
      worldCopyJump
    >
      <DetailsDialog open={open} handleClose={handleClose} location={currentLocation} />
      <TileLayer url={tileLayersURL} />
      <AddZoomControl />
      {locations.map((location, index) => {
        if (!location.latitude || !location.longitude) return null

        const markerIcon =
          location.employees.length > 0 && location.businesses.length > 0
            ? createPieChartMarkerFromCounts(50, 50)
            : location.employees.length > 0
              ? employeeMarker
              : businessMarker

        return (
          <Marker
            key={index}
            position={[location.latitude, location.longitude]}
            icon={markerIcon}
            riseOnHover={true}
            eventHandlers={{
              mouseover: () => handleMarkerMouseOver(index, location),
              mouseout: () => handleMarkerMouseOut(index),
            }}
          >
            {activeTooltip === index && (
              <Tooltip
                interactive={true}
                direction='bottom'
                offset={[0, 10]}
                opacity={1}
                permanent={true}
                className={styles.customTooltip}
                eventHandlers={{
                  mouseover: handleTooltipMouseOver,
                  mouseout: handleTooltipMouseOut,
                }}
              >
                <RegionTooltip
                  displayName={location.displayName}
                  countryCode={location.countryCode}
                  stateCode={location.stateCode}
                  employees={location.employees}
                  businesses={location.businesses}
                  setActiveTab={setActiveTab}
                  setEmployeeIds={setEmployeeIds}
                  handleClickOpen={handleClickOpen}
                />
              </Tooltip>
            )}
          </Marker>
        )
      })}
    </MapContainer>
  )
}

export default LeafletMap
