import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import Box from '@mui/material/Box'
import styles from './DetailsDialog.scss'
import { ReactSVG } from 'react-svg'
import { normalizeUrlProtocol } from 'containers/VerticalPage/common/utils'
import Divider from '@mui/material/Divider'
import { Location } from '../LeafletMap'

interface DetailsDialogProps {
  open: boolean
  handleClose: () => void
  location: Location | undefined
}

const DetailsDialog = ({ open, handleClose, location }: DetailsDialogProps) => {
  if (!location) return null

  const [visibleCount, setVisibleCount] = useState(5)
  const { displayName, countryCode, stateCode, businesses } = location

  const handleShowMore = () => {
    if (visibleCount >= businesses.length) {
      setVisibleCount(5)
    } else {
      setVisibleCount(prevCount => prevCount + 5)
    }
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box className={styles.dialogHeader}>
          <ReactSVG
            src={require(`../assets/${stateCode ? `stateFlags/${stateCode}` : `flags/${countryCode}`}.svg`)}
            className={styles.flagIcon}
          />
          <p className={styles.regionName}>{displayName}</p>
        </Box>
        {businesses.length > 0 && <Divider sx={{ margin: '8px -24px' }} />}
        <Box
          style={
            businesses.length === 0
              ? { display: 'none' }
              : visibleCount >= businesses.length && businesses.length > 5
                ? { paddingRight: 3 }
                : {}
          }
          className={styles.dialogBody}
        >
          <div className={styles.row}>
            <h3>Business Addresses Found</h3>
            <h3>Source</h3>
          </div>

          {businesses.slice(0, visibleCount).map((business, index) => (
            <div key={index} className={styles.row} style={{ borderBottom: '#dad8df 1px solid' }}>
              <p>
                {business.address.length > 35 ? (
                  <span title={business.address}>{business.address.substring(0, 35)}...</span>
                ) : (
                  business.address
                )}
              </p>
              <div
                style={{
                  display: 'flex',
                  gap: '6px',
                  paddingBottom: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'max-content',
                  marginRight: '6px',
                }}
              >
                {business.sources.map((source, sourceIndex) => (
                  <Box component='a' href={normalizeUrlProtocol(source.uri)} key={sourceIndex} target='_blank'>
                    {(() => {
                      try {
                        return (
                          <ReactSVG
                            style={{ borderRadius: '2px' }}
                            src={require(`../assets/socials/${source.platform}.svg`)}
                          />
                        )
                      } catch (error) {
                        console.warn(`SVG for ${source.platform} not found:`, error)
                        return null
                      }
                    })()}
                  </Box>
                ))}
              </div>
            </div>
          ))}

          {businesses.length > 5 && (
            <button type='button' className={styles.showMoreButton} onClick={handleShowMore}>
              {visibleCount >= businesses.length ? 'Hide Business Addresses' : '+5 Business Addresses'}
            </button>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  )
}

export default DetailsDialog
